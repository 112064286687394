import React from 'react';
import {
  FooterContainer,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  FooterSubscription,
  FooterSubHeading,
  FooterSubText,

  SocialIcon,
  WebsiteRights,
} from './Footer.elements';

function Footer() {

  const date = new Date();

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          Contact Us / Support
        </FooterSubHeading>
        <FooterSubText>Email : Usman.sep.20@gmail.com</FooterSubText>
        {/* <FooterSubText>Phone Number : +6017287 8485</FooterSubText> */}
      </FooterSubscription>
      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            {/* <SocialIcon /> */}
            Translate-AI
          </SocialLogo>
          <WebsiteRights>Translate-AI © {date.getFullYear()} </WebsiteRights>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  );
}

export default Footer;